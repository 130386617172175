import { SVGAttributes, SVGProps, forwardRef } from 'react'

export const VisitIcon = forwardRef<
  SVGSVGElement,
  SVGAttributes<SVGSVGElement>
>((props: SVGProps<SVGSVGElement>, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 26 31"
    {...props}
  >
    <path
      fill="currentColor"
      d="M10.182 14.9223c0-.0569 0-.1897-.056-.3415-.1059-.2847-.35498-.4871-.62899-.525-.19929-.0316-.39234.0063-.58539.1265-.28647.1834-1.10228.6831-1.90563 1.1764-.79713.487-1.57557.9677-1.80599 1.1132-.34251.2213-.49198.5692-.44838 1.0878v1.9354c-.01246.1202-.05605.4807.22419.797.11832.1328.24287.2024.3176.2466.49821.2847 3.08887 1.8532 3.5933 2.1821.04982.0316.20551.1328.41724.1455h.04982c.37366 0 .70373-.2593.80333-.6388.0374-.1392.0498-.1898.0249-6.5842v-.721Zm-1.48212 5.9011c-.82826-.506-1.90563-1.1575-2.45365-1.4801v-1.7456c.34874-.215.93413-.5756 1.53197-.9424.31138-.1897.62899-.3858.90923-.5629 0 1.227.01245 3.3901.01245 4.731ZM25.0721 7.11746 14.1677.330926c-.3675-.221369-.7536-.32889071-1.1521-.32889071-.4048-.01897449-.8096.09487231-1.2082.33521571L.927904 7.11746C.348743 7.49695 0 8.13576 0 8.84414V22.2338c0 .7021.342515 1.3409.921677 1.7203L11.8012 30.6964c.3238.2024.685.3036 1.04.3036.3176 0 .629-.0822.9154-.2403.5232-.2973 10.5308-6.3185 10.5308-6.3185l.7598-.4681.0311-.019c.5792-.3794.9217-1.0182.9217-1.7203V8.84414c0-.70206-.3487-1.34719-.9279-1.72668ZM20.115 24.321c-1.8372 1.1068-4.0106 2.4034-5.5239 3.2762V9.4956l5.5239 3.3269V24.321Zm3.668-2.3845c0 .1012-.0498.1961-.1308.2467 0 0-.7037.4238-1.7499 1.0562V12.3038c0-.3225-.1682-.6198-.4422-.7843l-7.3173-4.40204c-.2741-.16444-.6228-.17077-.903-.00632-.2803.16444-.4546.46171-.4546.7906V28.5902c-.0436.019-.0748.0317-.081.0253L2.34156 22.1832c-.08096-.0506-.13078-.1455-.13078-.2467V9.13508c0-.10119.04982-.19607.13078-.24667L12.7166 2.43077c.081-.0506.162-.08222.2554-.0759h.0498c.0934-.00632.1744.0253.2553.0759l10.3751 6.45764c.081.0506.1308.14548.1308.24667V21.9365Z"
    />
  </svg>
))
