import { SVGAttributes, SVGProps, forwardRef } from 'react'

export const SafetyInductionIcon = forwardRef<
  SVGSVGElement,
  SVGAttributes<SVGSVGElement>
>((props: SVGProps<SVGSVGElement>, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 27 31"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.534 6.38052c-.2617-.11235-.5617-.08114-.7787.08114L8.91702 9.6199c-.2234.16228-.33191.4307-.28085.6991.02553.1185.0766.2371.15319.337.08936.1061.21702.1935.35745.2434l6.05749 1.2359c.2489.0873.5234.0437.7276-.0999.2043-.1436.3128-.3932.2872-.6429L15 6.99844c-.0255-.26839-.2043-.50557-.4596-.61792h-.0064Zm-3.5489 3.59515 2.7447-1.9661.6064 2.54653-3.3511-.58043Z"
    />
    <path
      fill="currentColor"
      d="M25.7426 6.71757 15.1213.413568C14.6234.126456 14.1-.0171007 13.5702.00162402 13.0468-.00461756 12.517.138939 12.0191.432293L1.36596 6.64891C.580851 7.14199.114894 7.97212.114894 8.87091L0 22.0719c0 .9175.47234 1.7601 1.26383 2.2532L12 30.5854c.4723.2746.9638.4119 1.4681.4119h.083c.5106.025 1.0212-.1248 1.5446-.4306l10.6469-6.1792C26.5277 23.8944 27 23.0518 27 22.1343V8.96454c0-.91752-.4723-1.76013-1.2574-2.25322v.00625Zm-.8043 15.35433c0 .0998-.0511.1997-.1404.2496l-10.9788 6.5474c-.0893.05-.1723.0874-.2744.0749h-.0383c-.1021.0063-.1915-.0249-.2745-.0749L2.25319 22.3215c-.08936-.0562-.14042-.1498-.14042-.2496l.02553-2.8961 11.2915 6.7471c.1596.0687.1979.0874.3574 0l11.1447-6.8158v2.971l.0064-.0062Zm0-5.0245s-.0128.0437-.0319.0562l-11.2915 6.8782s-.0511.0125-.0702 0L2.18936 17.1909s-.03191-.0312-.03191-.0561l.03191-3.4454c0-.0499.05745-.0811.10213-.0499l11.15111 6.6597c.1404.0937.2617.0188.2617.0188l11.1319-6.7846c.0446-.025.1021 0 .1021.0561v3.4579Zm0-5.6736c0 .1061-.0574.206-.1532.2684l-11.0234 6.7159c-.1085.0687-.2489.0687-.3574 0L2.3617 11.7608c-.09574-.0562-.15957-.1623-.15957-.2747l.02553-2.59022c0-.09987.05106-.19973.14043-.24966L13.2894 2.0988c.0829-.04994.1723-.08739.268-.08115h.0575c.0957-.00624.1851.02497.2681.08115l10.9213 6.54742c.0829.05617.1404.14979.1404.24966v2.47792h-.0064Z"
    />
  </svg>
))
