import { SVGAttributes, SVGProps, forwardRef } from 'react'

export const OutlookCalendarIcon = forwardRef<
  SVGSVGElement,
  SVGAttributes<SVGSVGElement>
>((props: SVGProps<SVGSVGElement>, ref) => (
  <svg
    ref={ref}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill="#1976d2"
      d="M28 13h14.533c.81 0 1.467.657 1.467 1.467v19.066c0 .81-.657 1.467-1.467 1.467H28V13z"
    />
    <path fill="#fff" d="M28 17.958h14V33.5H28z" />
    <path fill="#1976d2" d="M27 44 4 39.5v-31L27 4z" />
    <path
      fill="#fff"
      d="M15.25 16.5c-3.176 0-5.75 3.358-5.75 7.5s2.574 7.5 5.75 7.5S21 28.142 21 24s-2.574-7.5-5.75-7.5zm-.25 12c-1.657 0-3-2.015-3-4.5s1.343-4.5 3-4.5 3 2.015 3 4.5-1.343 4.5-3 4.5z"
    />
    <path
      fill="#1976d2"
      d="M28.047 29.737h2.7v2.9h-2.7zM31.448 29.737h2.7v2.9h-2.7zM34.849 29.737h2.7v2.9h-2.7zM28.047 26.159h2.7v2.9h-2.7zM31.448 26.159h2.7v2.9h-2.7zM34.849 26.159h2.7v2.9h-2.7zM38.25 26.159h2.7v2.9h-2.7zM28.047 22.706h2.7v2.9h-2.7zM31.448 22.706h2.7v2.9h-2.7zM34.849 22.706h2.7v2.9h-2.7zM38.25 22.706h2.7v2.9h-2.7zM31.448 19.112h2.7v2.9h-2.7zM34.849 19.112h2.7v2.9h-2.7zM38.25 19.112h2.7v2.9h-2.7z"
    />
  </svg>
))
