import { SVGAttributes, SVGProps, forwardRef } from 'react'

export const PermitToWorkIcon = forwardRef<
  SVGSVGElement,
  SVGAttributes<SVGSVGElement>
>((props: SVGProps<SVGSVGElement>, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 260 309"
    fill="none"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M41.7831 132.022v69.993c0 2.791 1.4995 5.483 3.8985 6.979l64.8754 39.384c3.398 2.093 7.697-.399 7.697-4.288v-60.521l-32.1879 22.334s-.3998.299-.5998.499c-2.0992 1.396-4.3983 1.994-6.6974 1.994-3.5986 0-7.0972-1.595-9.4963-4.587l-12.0954-14.357c-5.2979-6.481-5.2979-8.774 1.4995-13.859 6.5974-4.786 8.2968-5.683 13.8946 1.495l4.9981 5.783c1.3994 1.596 3.6985 1.895 5.3979.698l25.1907-17.149-1-.598-59.877-36.792c-2.399-1.495-5.5978.3-5.5978 3.091l.0999-.099Z"
    />
    <path
      fill="currentColor"
      d="m252.603 71.6017-5.498-3.39L139.846 2.70536h-.1c-1.199-.79764-4.698-2.891454-9.696-2.6920438-3.299-.0997053-6.498.7976428-9.696 2.6920438L7.59708 71.502h-.19993C2.79892 74.5929 0 79.6778 0 85.1616V221.558c0 5.484 2.79892 10.569 7.39715 13.56h.09997l5.49788 3.39 113.256 68.298.6.3c1.7.897 4.299 1.894 7.497 1.894 3.499 0 7.897-1.097 12.895-4.686 1.9-1.296 99.762-65.806 99.762-65.806l5.398-3.29h.2c4.598-3.091 7.397-8.176 7.397-13.66V85.1616c0-5.4838-2.799-10.5687-7.397-13.5599ZM238.808 216.773c0 .997-.5 1.894-1.299 2.492l-25.69 15.654c-1.7 1.097-3.199 0-3.199-2.094l-1.2-123.036c0-.997-.5-1.895-1.299-2.393l-80.169-49.5536c-1.5-.8974-3.399.0997-3.399 1.7947v22.2343l62.876 40.8796c.799.498 1.299 1.495 1.299 2.492v126.726c0 .997-.5 1.894-1.299 2.492l-21.092 14.557c-1 .598-3.399-.797-3.199-2.094l-.3-125.13c0-.997-.5-1.894-1.299-2.393L70.5729 88.1528c-1.3995-.7976-3.0989.1994-3.0989 1.7947v23.9295l70.673 44.369c.8.498 1.299 1.495 1.299 2.492l-.699 117.852c0 2.193 1.299 7.478-5.898 7.278L22.5913 219.365c-.7997-.499-1.2995-1.496-1.2995-2.493V90.1469c0-.9971.4998-1.8944 1.2995-2.4926L127.251 23.6435c.8-.4986 1.599-.7977 2.599-.7977h.5c.999 0 1.799.2991 2.599.7977l104.66 64.0108c.799.4985 1.299 1.4955 1.299 2.4926V216.872l-.1-.099Z"
    />
  </svg>
))
