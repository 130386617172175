import { forwardRef, SVGAttributes, SVGProps } from 'react'

export const SuspensionsIcon = forwardRef<
  SVGSVGElement,
  SVGAttributes<SVGSVGElement>
>((props: SVGProps<SVGSVGElement>, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 27 31"
    ref={ref}
    {...props}
  >
    <path
      fill="#324A51"
      d="m13.983 18.911 4.062-2.357a.667.667 0 0 0 .332-.584v-3.724a.346.346 0 0 0-.519-.303l-7.906 4.402a.354.354 0 0 0 0 .616l3.366 1.95a.685.685 0 0 0 .665 0Zm-.145-12.715a.685.685 0 0 0-.665 0L9.11 8.553a.666.666 0 0 0-.333.585v3.87c0 .198.219.333.395.229l8.166-4.548c.176-.104.187-.355 0-.459l-3.501-2.024v-.01Zm11.905.313L14.773.344a2.525 2.525 0 0 0-2.546 0L1.267 6.592A2.577 2.577 0 0 0 0 8.804V21.57c0 .908.488 1.753 1.267 2.212l10.97 6.258c.396.23.832.334 1.268.334.436 0 .873-.115 1.268-.344l10.326-5.967.634-.365A2.577 2.577 0 0 0 27 21.488V8.72c0-.907-.488-1.752-1.267-2.211h.01Zm-19.52-.323 6.97-4.037a.607.607 0 0 1 .624 0l6.97 4.047v12.747l-7.303 4.11-7.272-4.142V6.186h.01Zm18.751 15.24c0 .229-.114.427-.311.542l-10.846 6.258a.607.607 0 0 1-.623 0L2.348 22.041a.627.627 0 0 1-.312-.543V8.866c0-.23.114-.427.312-.542l2.4-1.387v12.746c0 .25.135.47.342.595l8.052 4.485s.02.01.03.021h.042s.021 0 .042.01a.786.786 0 0 0 .436 0s.021 0 .031-.01h.042s.02 0 .031-.02l8.093-4.528a.671.671 0 0 0 .343-.595V6.865l2.4 1.387a.626.626 0 0 1 .311.542v12.632h.031Z"
    />
  </svg>
))
