import { SVGAttributes, SVGProps, forwardRef } from 'react'

export const ShipServiceIcon = forwardRef<
  SVGSVGElement,
  SVGAttributes<SVGSVGElement>
>((props: SVGProps<SVGSVGElement>, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18.77 24.02"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      strokeLinecap="round"
      strokeWidth="1.1"
      fill="none"
      d="M5.91 22h1.42a.8.8 0 0 0 .56-.24l.51-.51A.76.76 0 0 1 9 21h6a.74.74 0 0 1 .56.24l.51.51a.8.8 0 0 0 .57.24h1.52M5.91 22h-.59v1.29h13.34V22h-.48M5.91 22c-.12-1.62 0-4.39 2.12-5.86M18.18 22c0-1.57 0-4.35-2.24-5.84m-7.94 0v-.33a.66.66 0 0 1 .65-.66h.18a.48.48 0 0 1 .48.48h0M8 16.16v2.13a.65.65 0 0 0 .65.65h0a.66.66 0 0 0 .66-.65v-2.64m0 0a5 5 0 0 1 1.37-.4m4 .4v2.66a.63.63 0 0 0 .63.63h0a.64.64 0 0 0 .64-.63v-2.13m-1.27-.53h0a.48.48 0 0 1 .47-.48h.16a.64.64 0 0 1 .64.64v.37m-1.27-.53a5.47 5.47 0 0 0-1.36-.38m-2.6 0L11 18a.78.78 0 0 0 .78.7h.41A.8.8 0 0 0 13 18l.31-2.68m-2.6 0v-.47a.79.79 0 0 1 .79-.8h1a.8.8 0 0 1 .8.8v.49M6 17.36h-.21a.82.82 0 0 1-.79-.53l-.61-1.54a16.34 16.34 0 0 1-1.09-4.35h0a.46.46 0 0 1 .26-.48l2-1.05m6.33-3.26 3.22 1.63m-3.17-1.63L8.78 7.78m3.16-1.63V3.47m5.94 13.89h.41a.7.7 0 0 0 .65-.44l.67-1.59a14.29 14.29 0 0 0 1.08-4.45h0a.41.41 0 0 0-.22-.39l-3.85-2M12 7.82v4.75M5.61 9.41v-.88m0 .88 1.71-.88m4.62-5.06V.69h3.22m-3.22 2.78h6.43m-6.43 0H8.78m9.59 5.94v-.88m0-5.06V.69h-3.21m3.21 2.78v2.68M15.16.69v7.09m0 0 1.46.75m-11-2.38V3.47h3.16M5.61 6.15h12.76m-12.76 0v2.38m12.76-2.38v2.38M8.78 3.47v4.31m0 0-1.46.75m-1.71 0h1.71m9.3 0h1.75"
      transform="translate(-2.61 .01)"
    />
  </svg>
))
