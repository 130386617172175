import { SVGAttributes, SVGProps, forwardRef } from 'react'

export const MaintenanceOperatorIcon = forwardRef<
  SVGSVGElement,
  SVGAttributes<SVGSVGElement>
>((props: SVGProps<SVGSVGElement>, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    ref={ref}
    {...props}
  >
    <path
      d="M2.79,18.21h-1.57c-.2,0-.39-.2-.39-.39V6.22c0-.92.72-1.64,1.64-1.64h2.95c.98,0,1.77.79,1.77,1.77v1.7c3.41.13,3.27,3.01,3.54,4,.07.2.2.26.39.26h1.05c.2,0,.39-.2.39-.39v-5.5h-1.11c-.52,0-.92-.39-.92-.92h0c0-.52.39-.92.92-.92h5.31c.39,0,.72.33.72.72h0c0,.39-.33.72-.72.72h-1.18c-.13,0-.2.2-.13.26l2.03,1.9v7.34h-7.79c-.33,0-.52.26-.59.52v2.03h-2.1M9.08,18.08v1.18c0,.33.26.65.65.65h9.1c.33,0,.65-.33.65-.65v-3.08c0-.33-.33-.65-.65-.65h-1.38M4.89,15.66c-1.18,0-2.1.98-2.1,2.1s.98,2.1,2.1,2.1,2.1-.98,2.1-2.1-.92-2.1-2.1-2.1ZM19.55,13.36c.99,0,1.83-.84,1.83-1.83M21.33,11.53c0,.99.79,1.83,1.83,1.83M23.16,13.36c-.99,0-1.83.84-1.83,1.83M21.33,15.2c0-.99-.79-1.83-1.83-1.83"
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      strokeLinejoin="round"
      stroke-width="1.5"
    />
  </svg>
))
