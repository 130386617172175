import { SVGAttributes, SVGProps, forwardRef } from 'react'

export const LogoutIcon = forwardRef<
  SVGSVGElement,
  SVGAttributes<SVGSVGElement>
>((props: SVGProps<SVGSVGElement>, ref) => (
  <svg
    ref={ref}
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20.49 20"
  >
    <path
      fill="currentColor"
      d="M10.93,17.76c.24.24.38.58.38.93s-.14.68-.38.93c-.24.25-.58.39-.93.39-2.67,0-5.18-1.04-7.07-2.93-1.89-1.89-2.93-4.4-2.93-7.07S1.04,4.82,2.93,2.93C4.82,1.04,7.33,0,10,0c.35,0,.69.14.93.38.25.25.38.58.38.93s-.14.68-.38.93c-.24.24-.58.38-.93.38-1.97,0-3.82.77-5.21,2.16s-2.16,3.24-2.16,5.21.77,3.82,2.16,5.21c1.39,1.39,3.24,2.16,5.21,2.16.35,0,.69.14.93.38ZM20.1,9.05l-3.27-3.27c-.24-.23-.56-.35-.9-.35h-.02c-.34,0-.66.14-.91.38-.24.24-.38.56-.38.91s.12.67.35.92l1.03,1.03h-7.69c-.35,0-.68.14-.93.38-.25.25-.38.58-.38.93s.14.68.38.93c.25.25.58.38.93.38h7.69l-1.01,1.01c-.13.12-.23.26-.3.42-.07.16-.11.33-.11.51,0,.18.03.35.1.52.07.16.17.31.29.44s.27.22.44.29c.15.06.32.1.49.1h.03c.18,0,.35-.04.51-.11.16-.07.3-.17.42-.29l3.25-3.25c.25-.25.38-.58.38-.93s-.14-.68-.38-.93Z"
      stroke-width="0"
    />
  </svg>
))
